<template>
    <LayoutOneColumn :maxWidth="600">
<!--        <div class="mb-2">-->
<!--            <v-btn depressed block to="/">-->
<!--                <v-icon size="18" style="padding: 2px 2px 0 2px;">mdi-arrow-left</v-icon>-->
<!--                <span>Вернуться на главную</span>-->
<!--            </v-btn>-->
<!--        </div>-->

        <CardProfile
            v-if="$store.main.s.app === 'customer'"
            :profile="$store.profile.d"
            :showEmptyFields="true"
            class="short"
            @edit="$store.main.d.isShowProfileEditor = true"
        />

        <div class="mt-4">
            <h3>Настройки</h3>
            <v-switch
                    v-model="$store.persist.d.isThemeDark"
                    label="Темная тема"
                    hide-details
            />

            <v-btn
                    @click="removeLocalData"
                    depressed
                    color="warning"
                    class="mt-4"
            >Удалить локальные данные
            </v-btn>
        </div>

        <div class="my-4" v-if="$store.main.s.app === 'admin'">
            <h3>Аккаунт</h3>
            <div class="mt-2">
<!--                <v-btn-->
<!--                        depressed-->
<!--                        to="/profile/change-password"-->
<!--                        color="warning"-->
<!--                >-->
<!--                    Поменять пароль-->
<!--                </v-btn>-->
                <v-btn
                        depressed
                        color="error"
                        @click="signOut"
                >
                    Выйти из аккаунта
                </v-btn>
            </div>
        </div>

      <ViewDialog v-model="$store.main.d.isShowProfileEditor" title="Редактирование профиля">
        <InputProfile :profile="$store.profile.d" @save="saveProfile"/>
      </ViewDialog>
    </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import CardProfile from "@/ui/components/CardProfile.vue";
import ViewDialog from "@/ui/components/ViewDialog.vue";
import InputProfile from "@/ui/components/InputProfile.vue";

export default {
  name: 'PageProfile',
  components: {InputProfile, ViewDialog, CardProfile, LayoutOneColumn},
  methods: {
    saveProfile(v) {
      this.$store.profile.d.surname = v.surname;
      this.$store.profile.d.name = v.name;
      this.$store.profile.d.middleName = v.middleName;
      this.$store.profile.d.email = v.email;
      this.$store.profile.d.phone = v.phone;
      this.$store.profile.d.city = v.city;
      this.$store.profile.d.company = v.company;
      // this.$store.profile.d.isInner = v.isInner;
      this.$store.persist.s.profile = JSON.stringify(this.$store.profile.d);
      this.$store.main.d.isShowProfileEditor = false;
    },
    removeLocalData() {
      this.$api.app.openConfirmDialog({
        title: 'Удаление локальных данных',
        text: 'Вы уверены, что хотите удалить данные из локального хранилища?',
        ok: () => {
          this.$api.main.cleanLocalData();
          window.location.reload();
        },
      });
    },
    signOut() {
      this.$api.app.openConfirmDialog({
        title: 'Выход из аккаунта',
        text: 'Вы уверены, что хотите выйти из аккаунта?',
        ok: () => {
          this.$api.auth.signOut();
          this.$router.push('/login').catch(() => {
          });
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
