<template>
  <v-app
    v-if="$store.persist.d.isDefinedInnerOuter && $store.profile.d.isInner && !$store.profile.d.isInnerLoggedIn"
    class="fill-view-height"
    style="min-width: 340px;"
  >
    <v-main class="fill-height">
      <PageLoginCode/>
    </v-main>

    <ConfirmDialog/>
    <SnackBar/>
  </v-app>
    <v-app v-else-if="isExcludedAuth" class="fill-view-height" style="min-width: 340px;">
        <v-main class="fill-height">
            <transition name="fade" mode="out-in">
                <router-view :key="$route.path"/>
            </transition>
        </v-main>

        <ConfirmDialog/>
        <SnackBar/>
    </v-app>
    <v-app
            v-else-if="loaded && !errored"
            class="fill-view-height"
            style="min-width: 340px;"
    >
        <AppBar/>

        <v-main class="fill-height">
            <transition name="fade" mode="out-in">
                <router-view :key="$route.path"/>
            </transition>
        </v-main>

        <ConfirmDialog/>
        <SnackBar/>
    </v-app>

    <v-app v-else-if="loading" class="fill-view-height">
        <transition name="fade" mode="out-in">
            <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
            ></v-progress-linear>
        </transition>
    </v-app>
    <v-app v-else-if="errored" class="fill-view-height">
        <transition name="fade" mode="out-in">
            <LayoutOneColumn>
                <LogoBlock/>
                <DataError :error="error"/>
            </LayoutOneColumn>
        </transition>
    </v-app>
    <v-app v-else class="fill-view-height">
        <transition name="fade" mode="out-in">
            <LayoutOneColumn>
                <LogoBlock/>
                <h1 class="my-4 text-center error--text">
                    Critical error
                </h1>
            </LayoutOneColumn>
        </transition>
    </v-app>
</template>

<script>
import LogoBlock from "@/ui/components/LogoBlock.vue";
import ConfirmDialog from "@/ui/components/ConfirmDialog";
import SnackBar from "@/ui/components/SnackBar.vue";
import AppBar from "@/ui/components/AppBar";
import DataError from "@/ui/components/DataError";
import LayoutOneColumn from "@/ui/layouts/LayoutOneColumn";
import PageLoginCode from "@/ui/pages/PageLoginCode.vue";

export default {
  name: 'App',
  components: {
    PageLoginCode,
    LayoutOneColumn,
    AppBar,
    SnackBar,
    ConfirmDialog,
    LogoBlock,
    DataError,
  },
  computed: {
    isExcludedAuth() {
      return this.$route.meta?.isExcludedAuth;
    },
    loading() {
      return !this.$store.main.d.isAppReady;
    },
    loaded() {
      return this.$store.main.d.isAppReady;
    },
    isAuth() {
      return !!this.$store.persist.d.authToken;
    },
    errored() {
      return false;
    },
    error() {
      return null;
    },
    isShowBottomNavigation() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.fill-view-height {
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.toolbar {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.toolbar__button-close {
  margin-right: 0 !important;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
@import 'styles/base.scss'
@import 'styles/style.sass'
</style>
