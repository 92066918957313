<template>
    <v-app-bar
            v-if="$route.path !== '/login'"
            app
            flat
            class="app-bar"
            :style="appTitleStyle"
    >
        <LogoBlock/>

        <h3 v-if="title" class="ml-2" style="line-height: 1;">{{ title }}</h3>

        <slot></slot>

        <v-spacer/>

        <v-btn
            v-if="$store.main.s.app === 'admin' || $store.persist.d.isDefinedInnerOuter"
          icon
          class="ml-2"
          @click="$store.main.d.isShowProfile = true"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>

        <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                color="primary"
        />

      <v-dialog
          v-model="$store.main.d.isShowProfile"
          scrollable
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          max-width="680"
      >
        <v-card tile class="app-dialog">
          <v-toolbar
              flat
              class="toolbar"
              dense
          >
            <v-toolbar-title>Профиль</v-toolbar-title>
            <v-spacer/>
            <slot name="toolbar"></slot>
            <v-btn
                icon
                @click="$store.main.d.isShowProfile = false"
                class="toolbar__button-close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <PageProfile/>
        </v-card>
      </v-dialog>

    </v-app-bar>
</template>

<script>
import LogoBlock from "@/ui/components/LogoBlock.vue";
import PageProfile from "@/ui/pages/PageProfile.vue";

export default {
  name: 'AppBar',
  components: {PageProfile, LogoBlock},
  data: () => ({
    showToolbar: false,
  }),
  computed: {
    appTitleStyle() {
      return {
        opacity: this.showToolbar ? 1 : 0,
        'overflow-x': this.$vuetify.breakpoint.mdAndUp ? 'none' : 'auto',
        'overflow-y': 'hidden',
      };
    },
    loading() {
      return false;
    },
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {},
  mounted() {
    this.showToolbar = true;
  },
}
</script>

<style scoped lang="scss">
.app-bar {
  transition: opacity .3s;
}

.link.v-btn--active {
  color: var(--v-primary-base)
}

.v-btn.dev {
  margin-top: -25px;
  margin-left: -50px;
  margin-right: 10px;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background: #fff;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
