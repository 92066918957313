<template>
  <div
      class="justify-center align-items-center"
      style="height: 100%; width: 100%"
  >
  <div class="my-4">
    <h1 class="text-center font-weight-light error--text">
      {{title}}
    </h1>
    <h5
        v-if="error"
        class="text-center"
    >{{error}}</h5>
  </div>
  </div>
</template>

<script>
export default {
  name: 'DataError',
  props: {
    error: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Произошла ошибка при получении данных',
    },
  },
};
</script>

<style scoped>

</style>
