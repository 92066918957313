import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/src/locale/ru.ts';
import en from 'vuetify/src/locale/en.ts';

export default function createVuetify({isDark = false}) {
  // Vuetify.config.silent = true;

  Vue.use(Vuetify);

  return new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#0078d2',
          secondary: '#0aa5ff',
          accent: '#4b5963',
          error: '#fe4343',
          info: '#0aa5ff',
          success: '#22c38e',
          warning: '#f38b00'
        },
        dark: {
          primary: '#0078d2',
          secondary: '#0aa5ff',
          accent: '#4b5963',
          error: '#fe4343',
          info: '#0aa5ff',
          success: '#22c38e',
          warning: '#f38b00'
        },
      },
      dark: isDark,
    },
    lang: {
      locales: { ru, en },
      current: 'ru',
    },
  });
}
