<template>
  <div
      class="d-flex"
      style="align-items: center; width: 100vw; height: 100vh;"
  >
    <v-card
        :tile="$vuetify.breakpoint.smAndDown"
        :elevation="$vuetify.breakpoint.smAndDown ? 0 : 1"
        class="mx-auto"
        style="max-width: 600px; overflow-y: auto; max-height: 100%; width: inherit; transition: all .2s;"
    >
      <div class="px-8" style="padding-bottom: 40px;">
        <div class="d-flex align-center">
          <LogoBlock style="margin-top: 20px"/>
        </div>
          <h2 class="my-4 text-center">Авторизация</h2>
          <div class="caption">Пароль</div>
          <v-text-field
                  v-model="authPassword"
                  :type="isShowPassword ? 'text' : 'password'"
                  :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="isShowPassword = !isShowPassword"
                  class="mb-4"
                  outlined
                  dense
                  hide-details
          />
          <v-btn
                  :loading="isAuthLoading"
                  @click="signIn"
                  depressed
                  block
                  large
                  color="primary"
                  style="font-size: 1.1rem;"
          >
              Войти
          </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import LogoBlock from '@/ui/components/LogoBlock.vue';
export default {
  name: 'PageLoginCode',
  components: {LogoBlock},
  data() {
    return {
      authPassword: '',
      isShowPassword: false,
      isAuthLoading: false,
    };
  },
  methods: {
    async signIn() {
      if (!this.authPassword) {
        this.$api.app.snackError('Пароль не может быть пустой');
        return;
      }
      try {
        this.isAuthLoading = true;
        if (this.authPassword === process.env.VUE_APP_LOGIN_CODE) {
          this.$store.profile.d.isInnerLoggedIn = true;
          this.$store.persist.s.profile = JSON.stringify(this.$store.profile.d);
          this.authPassword = '';
        } else {
          this.$api.app.snackError('Неверный пароль');
        }
      } catch (e) {
        console.error(e);
        this.$api.app.snackError('Что-то пошло не так');
      } finally {
        this.isAuthLoading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
