<template>
  <div style="width: 80px;">
    <div style="font-size: 1.2em; width: 80px; line-height: 1;">
      HR AI Services
    </div>
    <div v-if="$store.main.s.app === 'admin'" style="font-size: 0.6em; width: 80px; line-height: 1;">
      Admin panel
    </div>
    <div v-if="subtitle" style="font-size: 0.6em; width: 80px; line-height: 1;">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoBlock',
  props: ['subtitle'],
};
</script>

<style scoped>

</style>
