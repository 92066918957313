<template>
  <div class="flex-grow-1">
    <v-combobox
        v-if="isGPN"
        :value="value"
        :items="companies"
        :rules="rulesDefault"
        @input="v => $emit('input', v)"
        dense
        outlined
        hide-details
    />
    <v-text-field
        v-else
        :value="value"
        :rules="rulesDefault"
        @input="v => $emit('input', v)"
        outlined
        dense
        lang="ru"
        hide-details
    />
<!--    <v-switch-->
<!--        v-model="isGPN"-->
<!--        label="Компания из группы компаний ГПН"-->
<!--        hide-details-->
<!--        dense-->
<!--        class="mt-0 switch-dense"-->
<!--    />-->
  </div>
</template>

<script>
import CompaniesGpnChildren from '@/assets/CompaniesGpnChildren.json';
const collator = new Intl.Collator('ru', {sensitivity: 'base'});
export default {
  name: 'InputCompany',
  props: ['value'],
  data: () => ({
    companies: [
        'ПАО «Газпром нефть»',
        ...CompaniesGpnChildren.sort(collator.compare),
    ],
    isGPN: false,
  }),
  watch: {
    '$store.profile.d.isInner': {
      immediate: true,
      handler(v) {
        this.isGPN = v;
      },
    },
  },
  computed: {
    rulesDefault() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . .";
}

.grid-container > * {
}
</style>

<style>
.switch-dense > .v-input__control > .v-input__slot {
  align-items: start;
}
</style>
