import Vue from 'vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import createVuetify from '@/plugins/vuetify';
import AppPlugin from '@/utils/AppPlugin';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import './utils/WindowSize';

// patch

if (!window.crypto.randomUUID) {
  window.crypto.randomUUID = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

// init data for app

const plugins = [
  new AppPlugin('store', ['app', 'main', 'persist', 'profile']),
  new AppPlugin('api', ['auth', 'app', 'docs', 'main', 'DocumentGenerator'], true),
];

Vue.config.productionTip = false;

// for IDE highlighting
Vue.prototype.$api = null;
Vue.prototype.$store = null;

// Vue.prototype.$keycloak = Keycloak(initOptions);

(async function () {

  await Promise.all(plugins.map(async plugin => {
    Vue.use(plugin);
    await plugin.wait();
  }));

  // init routing logic

  router.beforeEach((to, from, next) => {

    if (to.path === '/dev' && !router.app.$store.persist.s.isDev) {
      next({path: '/'});
      return;
    }

    if (to.path !== '/404' && !router.app.$store.persist.d.isDefinedInnerOuter) {
      if (to.path === '/inner' || to.redirectedFrom === '/inner') {
        router.app.$store.persist.d.isDefinedInnerOuter = true;
        router.app.$store.profile.d.isInner = true;
        router.app.$store.profile.d.isInnerLoggedIn = false;
        router.app.$store.persist.s.profile = JSON.stringify(router.app.$store.profile.d);
        next({path: '/'});
        return;
      }
      if (to.path === '/outer' || to.redirectedFrom === '/outer') {
        router.app.$store.persist.d.isDefinedInnerOuter = true;
        router.app.$store.profile.d.isInner = false;
        router.app.$store.persist.s.profile = JSON.stringify(router.app.$store.profile.d);
        next({path: '/'});
        return;
      }
      next({path: '/404'});
      return;
    }

    next();
  });

  // render app

  const app = new Vue({
    router,
    vuetify: createVuetify({isDark: Vue.prototype.$store.persist.d.isThemeDark}),
    watch: {
      '$store.persist.d.isThemeDark': {
        handler(v) {
          app.$vuetify.theme.isDark = v;
        },
      },
      '$route.path'() {
        // TODO: fetch and process data by page opening
      },
    },
    render: h => h(App),
  });

  // init data for debug

  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window.__app = app;
  }

  // init app data

  plugins.forEach(plugin => {
    plugin.setVueInstance(app);
  });

  app.$store.app.toolbarLoading = true;

  // init request manager

  // Vue.prototype.$rm = new RequestManager(app);

  // mount app

  app.$mount('#app');

  // init app logic

  // check app version
  try {
    if (app.$store.persist.d.version?.toString() !== app.$store.main.d.version?.toString()) {
      app.$api.main.cleanLocalData(); // TODO: change logic after 1.0 release
      app.$store.persist.d.version = app.$store.main.d.version.toString();
      window.location.reload();
    }

    /**
     *  если бэкенд был запущен последний раз позже, чем мы кэшировали данные,
     *  то обновляем мета данные, иначе обрабатываем сырые мета данные из кэша.
     *
     *  TODO: fetch and process data
     */

    // load or mock projects
    if (app.$store.persist.s.projects) {
      try {
        app.$store.main.s.projects = JSON.parse(app.$store.persist.s.projects).sort((a, b) => b.createdAt - a.createdAt)
      } catch (e) {
        console.error(e);
      }
    } else {
      app.$store.persist.s.projects = JSON.stringify([]);
      app.$store.main.s.projects = [];
    }

    // load or mock profile
    const useProfile = (profile) => {
      app.$store.profile.d.surname = profile.surname;
      app.$store.profile.d.name = profile.name;
      app.$store.profile.d.middleName = profile.middleName;
      app.$store.profile.d.email = profile.email;
      app.$store.profile.d.phone = profile.phone;
      app.$store.profile.d.city = profile.city;
      app.$store.profile.d.company = profile.company;
      app.$store.profile.d.isInner = profile.isInner;
      app.$store.profile.d.isInnerLoggedIn = profile.isInnerLoggedIn;
    }

    if (app.$store.persist.s.profile) {
      try {
        useProfile(JSON.parse(app.$store.persist.s.profile));
      } catch (e) {
        console.error(e);
      }
    }

    // app is ready
    app.$store.main.d.isAppReady = true;

  } catch (e) {
    console.error('Errored starting app');
    console.error(e);
  }
})();
