<template>
  <div class="flex-grow-1">
    <v-text-field
        :value="value"
        :rules="rulesDefault"
        @input="v => $emit('input', v)"
        outlined
        dense
        lang="ru"
        hide-details
    />
    <div class="mt-1">
      <v-chip @click="$emit('input', 'Москва')" small>
        Москва
      </v-chip>
      <v-chip @click="$emit('input', 'Санкт-Петербург')" class="ml-2" small>
        Санкт-Петербург
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputCity',
  props: ['value'],
  data: () => ({}),
  computed: {
    rulesDefault() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 640px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . .";
}

.grid-container > * {
}
</style>
