<template>
  <div v-if="localProfile" class="fill-height overflow-y-auto">
    <div class="mx-auto" style="width: 640px; max-width: 640px;">
      <div class="d-flex flex-row align-center mt-2">
        <div class="subtitle-1" style="width: 100px">Фамилия</div>
        <v-text-field
            v-model="localProfile.surname"
            :rules="rulesDefault"
            outlined
            dense
            lang="ru"
            hide-details
            ref="surname"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">Имя</div>
        <v-text-field
            v-model="localProfile.name"
            :rules="rulesDefault"
            outlined
            dense
            lang="ru"
            hide-details
            ref="name"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">Отчество</div>
        <v-text-field
            v-model="localProfile.middleName"
            :rules="rulesDefault"
            outlined
            dense
            lang="ru"
            hide-details
            ref="middleName"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">E-mail</div>
        <v-text-field
            :value="localProfile.email"
            :rules="rulesEmail"
            @input="inputEmail"
            outlined
            dense
            lang="ru"
            hide-details
            ref="email"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">Телефон</div>
        <v-text-field
            v-model="localProfile.phone"
            :rules="rulesPhone"
            @input="inputPhone"
            outlined
            dense
            lang="ru"
            hide-details
            ref="phone"
        />
      </div>
      <div v-if="!isExecutor" class="d-flex flex-row mt-4">
        <div class="subtitle-1 mt-2" style="width: 100px">Город</div>
<!--        <v-text-field-->
<!--            v-model="localProfile.city"-->
<!--            outlined-->
<!--            dense-->
<!--            lang="ru"-->
<!--            hide-details-->
<!--        />-->
        <InputCity v-model="localProfile.city"/>
      </div>
      <div v-if="!isExecutor" class="d-flex flex-row mt-4">
        <div class="subtitle-1 mt-2" style="width: 100px">Компания</div>
<!--        <v-text-field-->
<!--            v-model="localProfile.company"-->
<!--            outlined-->
<!--            dense-->
<!--            lang="ru"-->
<!--            hide-details-->
<!--        />-->
        <InputCompany v-model="localProfile.company"/>
      </div>
      <v-expand-transition>
        <div v-if="isError" class="pt-4">
          <v-alert type="error">
            Не все поля введены верно
          </v-alert>
        </div>
      </v-expand-transition>
      <v-btn depressed color="primary" block @click="save()" class="my-4">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import InputCompany from "@/ui/components/InputCompany.vue";
import InputCity from "@/ui/components/InputCity.vue";

export default {
  name: 'InputProfile',
  components: {InputCity, InputCompany},
  props: {
    profile: Object,
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExecutor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    localProfile: null,
    isError: false,
  }),
  watch: {
    profile: {
      immediate: true,
      handler() {
        this.localProfile = this.profile;
      },
    },
  },
  computed: {
    rulesEmail() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
        v => !!String(v).toLowerCase().match(/\S+@\S+\.\S+/) || 'Некорректный e-mail',
      ];
    },
    rulesPhone() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
        v => !!String(v).toLowerCase().match(/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/) || 'Некорректный номер телефона',
      ];
    },
    rulesDefault() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
  },
  methods: {
    save() {
      if (!this.required) {
        this.isError = false;
        this.$emit('save', this.localProfile);
        return;
      }

      this.$refs.surname.validate();
      this.$refs.name.validate();
      this.$refs.middleName.validate();
      this.$refs.email.validate();
      this.$refs.phone.validate();

      if (!this.$refs.surname.valid ||
          !this.$refs.name.valid ||
          !this.$refs.middleName.valid ||
          !this.$refs.email.valid ||
          !this.$refs.phone.valid) {
        this.isError = true;
        return;
      }

      if (!this.isExecutor &&
          (!this.localProfile.city ||
          !this.localProfile.company)) {
        this.isError = true;
        return;
      }

      this.isError = false;
      this.$emit('save', this.localProfile);
    },
    inputPhone(v) {
      // later do: not rus numbers

      const mask = '+7(###)###-##-##';
      let value = v.replace('+7', '').replaceAll(/\D/g, '');

      if (value === '7' || value === '8') {
        this.localProfile.phone = '+7';
        return;
      }

      if (value.length === 11 && (value[0] === '7' || value[0] === '8')) {
        value = value.slice(1);
      }

      value = value.slice(0, 10);

      let maskedString = '';
      let maskIndex = 0;

      for (let i = 0; i < value.length && maskIndex < mask.length; i++) {
        if (mask[maskIndex] === '#') {
          maskedString += value[i];
          maskIndex++;
        } else {
          maskedString += mask[maskIndex];
          maskIndex++;
          i--;
        }
      }

      console.log(maskedString);

      this.localProfile.phone = maskedString;
      this.$refs.phone.lazyValue = this.localProfile.phone;
    },
    inputEmail(v) {
      this.localProfile.email = v;
    },
  },
};
</script>

<style scoped>

</style>
