import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/other',
    name: 'PageOther',
    component: () => import(/* webpackChunkName: "other" */ '../ui/pages/PageOther.vue'),
    meta: {
      mainBottomNavigation: true,
    },
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../ui/pages/PageNotFound.vue'),
    meta: {
      mainBottomNavigation: false,
    },
  },

  {
    path: '/projects',
    name: 'PageProjects',
    component: () => import(/* webpackChunkName: "projects" */ '../ui/pages/PageProjects.vue'),
    meta: {
      title: 'Проекты',
    },
  },
  {
    path: '/description',
    name: 'PageProjectEmptyDescription',
    component: () => import(/* webpackChunkName: "project-description" */ '../ui/pages/PageProjectDescription.vue'),
    meta: {
      title: 'Описание проекта',
    },
  },
  {
    path: '/projects/:projectId',
    name: 'PageProject',
    component: () => import(/* webpackChunkName: "project" */ '../ui/pages/PageProject.vue'),
    meta: {
      title: 'Страница проекта',
    },
  },
  {
    path: '/projects/:projectId/description',
    name: 'PageProjectDescription',
    component: () => import(/* webpackChunkName: "project-description" */ '../ui/pages/PageProjectDescription.vue'),
    meta: {
      title: 'Описание проекта',
    },
  },

  {
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "dev" */ '../ui/pages/PageDev.vue'),
    meta: {
      mainBottomNavigation: true,
      title: 'Dev',
    },
  },
  {
    path: '/',
    redirect: '/projects',
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

export default router;
