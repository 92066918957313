<template>
  <div v-if="profile">
    <div class="d-flex align-center">
      <h3 v-if="name" class="mr-2">{{ name }}</h3>
      <div v-else class="font-italic mt-1" style="font-size: 14px">Имя не указано</div>
      <v-spacer/>
      <v-btn depressed @click="$emit('edit')">
        <v-icon small class="mr-1">mdi-pencil</v-icon>
        Редактировать
      </v-btn>
    </div>
    <div class="mt-2 d-flex flex-column flex-sm-row flex-wrap">
      <div v-if="profile.email || showEmptyFields" class="mr-2 my-1">
        <div class="caption">E-mail</div>
        <a v-if="profile.email" :href="`mailto:${profile.email}`" class="body-2">{{ profile.email }}</a>
        <div v-else class="font-italic mt-1" style="font-size: 12px">Не указано</div>
      </div>
      <div v-if="profile.phone || showEmptyFields" class="mr-2 my-1">
        <div class="caption">Телефон</div>
        <a v-if="profile.phone" :href="`tel:${profile.phone}`" class="body-2">{{ profile.phone }}</a>
        <div v-else class="font-italic mt-1" style="font-size: 12px">Не указано</div>
      </div>
      <div v-if="!isExecutor && (profile.city || showEmptyFields)" class="mr-2 my-1">
        <div class="caption">Город</div>
        <div v-if="profile.city" class="body-2" style="padding-top: 3px;">{{ profile.city }}</div>
        <div v-else class="font-italic mt-1" style="font-size: 12px">Не указано</div>
      </div>
      <div v-if="!isExecutor && (profile.company || showEmptyFields)" class="mr-2 my-1">
        <div class="caption">Компания</div>
        <div v-if="profile.company" class="body-2" style="padding-top: 3px;">{{ profile.company }}</div>
        <div v-else class="font-italic mt-1" style="font-size: 12px">Не указано</div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: 'CardProfile',
  props: ['profile', 'showEmptyFields', 'isExecutor'],
  computed: {
    name() {
      let res = '';
      if (this.profile.surname) {
        res += this.profile.surname + ' ';
      }
      if (this.profile.name) {
        res += this.profile.name + ' ';
      }
      if (this.profile.middleName) {
        res += this.profile.middleName;
      }
      return res;
    },
  },
};
</script>

<style scoped>

</style>
